import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import theme from '../../../gatsby-theme-material-ui-top-layout/theme';
import { loadPublicProjectsIdRequest } from '../../../store/access';
import { accessSelectors } from '../../../store/access/selectors';
import { projectFlow } from '../../../store/project';
import { ProjectRender } from '../ProjectRender/ProjectRender';

const containerStyle = {
  height: '100%',
  width: '100%',
  maxWidth: '960px',
  background: theme.palette.background.paper,
  marginTop: '50px',
  padding: '0px',
  margin: '0 auto',
  '&:MuiDataGrid-colCellTitle': {
    fontWeight: 700,
    color: 'red',
  },
};

const groupNameContainer = {
  width: '200px',
  margin: '20px 0 20px 100px',
};

const PublicProjectsView: React.FC = () => {
  const dispatch = useDispatch();
  const publicProjectIds: string[] = useSelector(accessSelectors.publicProjectIds);
  useEffect(() => {
    dispatch(projectFlow('publicProjectView'));
    dispatch(loadPublicProjectsIdRequest());
  }, []);

  return (
    <Box sx={containerStyle}>
      <Box sx={groupNameContainer} />
      <div style={{}}>
        {publicProjectIds.map((projectId: string) => {
          return <ProjectRender key={projectId} id={projectId} parent={'publicProjectView'} />;
        })}
      </div>
    </Box>
  );
};
export { PublicProjectsView };

import { useAuth0 } from '@auth0/auth0-react';
import { navigate } from '@gatsbyjs/reach-router';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import theme from '../../../gatsby-theme-material-ui-top-layout/theme';
import { accessSelectors } from '../../../store/access/selectors';
import { authSelectors } from '../../../store/auth';
import {
  Project,
  loadAllSubProjects,
  loadProject,
  projectFlow,
  projectHierRootProjectId,
  projectSelectors,
} from '../../../store/project';
import { ProjectRender } from '../ProjectRender/ProjectRender';

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
    maxWidth: '960px',
    background: theme.palette.background.paper,
    marginTop: '50px',
    padding: '0px',
    margin: '0 auto',
  },
  groupNameContainer: {
    width: '200px',
    margin: '20px 0 20px 100px',
  },
});

const ProjectHierView: React.FC<{
  projectId: string;
  isUserDashboard?: boolean;
}> = ({ projectId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const project = useSelector(projectSelectors.projectById(projectId));
  const projectAccess = useSelector(accessSelectors.getAccessById(projectId));
  const accessEmails = useSelector(accessSelectors.getEmailsByProjectId(projectId));
  const flow: string = useSelector(projectSelectors.projectFlow);
  const { user } = useAuth0();
  const authStateFinalized = useSelector(authSelectors.authStateFinalized);
  const allProjects: Project[] = useSelector(projectSelectors.allProjects);

  useEffect(() => {
    if (projectId) {
      dispatch(loadProject(projectId));
    }
  }, [projectId]);

  useEffect(() => {
    if (flow.length == 0) {
      dispatch(projectFlow('projectHierView'));
      dispatch(projectHierRootProjectId(projectId));
    }
  }, [flow]);

  const hasAccess = () => {
    if (!accessEmails) {
      return true;
    }
    const userEmail = (user && user.email) || '';
    return accessEmails.includes(userEmail);
  };

  useEffect(() => {
    if (!authStateFinalized) {
      return;
    }

    if (project.hasOwnProperty('id')) {
      if (projectAccess && !hasAccess() && !projectAccess.publicAccess) {
        navigate('/');
      }
    }

    if (projectId) {
      dispatch(loadAllSubProjects(projectId));
    }
  }, [projectId, authStateFinalized, projectAccess]);

  return (
    <div className={classes.root}>
      <div className={classes.groupNameContainer} />
      <div style={{}}>
        {allProjects.map((project: Project) => {
          return (
            <ProjectRender
              key={project.id}
              id={project.id}
              parent={flow && flow.length > 0 ? flow : 'projectHierView'}
            />
          );
        })}
      </div>
    </div>
  );
};
export { ProjectHierView };
